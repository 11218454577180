import React from 'react';

const AuthLoading = () => {
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', 
    }
    
    const imageStyle = {
        width: '20%',  
    };

    return (
        <div style={containerStyle} className='loading'>
            <img src='images/load.svg' alt="loading..." style={imageStyle} />
        </div>
    )
};

export default AuthLoading;