import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import Button from '@mui/material/Button';
import { UserContext } from "../contexts/user.context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate } from '@fortawesome/free-solid-svg-icons'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { render } from "@testing-library/react";

const Highlight = ({ dashboardId }) => {

  const { getUserToken } = useContext(UserContext);

  const sdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-reporting-cgsdk",
    getUserToken: () => getUserToken()
  });

  const dashboardDiv = useRef(null);
  const [charts, setCharts] = useState({});
  const [subCharts, setSubCharts] = useState({});
  const chartDiv = useRef(null);
  const [rendered, setRendered] = useState(false);
  const [orgNames, setOrgNames] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedChartId, setSelectedChartId] = useState(null);
  const [selectedLearningPath, setSelectedLearningPath] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedUserDetail, setSelectedUserDetail] = useState(null);
  const [selectedComponentId, setSelectedComponentId] = useState(null);
  const [selectedOrgType, setSelectedOrgType] = useState(null);
  const [selectedOrgLanguage, setSelectedOrgLanguage] = useState(null);
  const selectedOrganizationsRef = useRef(null);
  const selectedChartIdRef = useRef(selectedChartId);
  const selectedLearningPathRef = useRef(null);
  const selectedUserRef = useRef(null);
  const selectedTopicRef = useRef(null);
  const selectedOrgTypeRef = useRef(null);
  const selectedOrgLanguageRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let chartRefs = {};
  let subChartRefs = {};
  const rows = [];
  let chartName;
  let formattedDate;
  const { logOutUser } = useContext(UserContext);

  const [dashboard, setDashboard] = useState(null)

  useEffect(() => {
    const renderDashboard = async () => {
      console.log(selectedOrganizations)
      if (selectedOrganizations.length === 0) {
        return;
      }
      const tempDashboard = sdk.createDashboard({
        container: dashboardDiv.current,
        dashboardId: dashboardId,
        height: "1000px",
        width: "100%",
        theme: "light",
        background: "#CEE6F3",
        autoRefresh: false,
        filter: {"organization": { $in: selectedOrganizations }}          
      })

      setDashboard(tempDashboard)

      tempDashboard.render(dashboardDiv.current).then(async (a) => {
        // dashboard.addEventListener('dataLoaded', async () => {
          const charts = await tempDashboard.getAllCharts();
          charts.forEach((chart) => {
            
            switch (chart.chartId) {
              case "6492eee1-e63f-483e-8d72-0f06bc333cbd":
                chartRefs.organizationsChart = chart;
                break; 

              case "50635938-1e6f-4c05-841e-de6779b00f89":
                chartRefs.totalUsersChart = chart;
                chartRefs.totalUsersChart.chartName = 'Total # of LP`s users joined';
                break;

              case "6544ddb3-903f-4e66-81ca-5ccc49f4c736":
                chartRefs.accountManagerChart = chart;
                chartRefs.accountManagerChart.chartName = 'Account Manager';
                break; 

              case "d98cfc44-dafc-448b-ae95-d6d88851d0c4":
                chartRefs.orgLanguagesChart = chart;
                chartRefs.orgLanguagesChart.chartName = 'Organization Languages';
                chart.addEventListener("click", handleOrgLanguagesChartClick);  
                break;

              case "0042022c-6ad8-43eb-a49a-a27ad1fce993":
                chartRefs.lpTypeByUsersChart = chart;
                chartRefs.lpTypeByUsersChart.chartName = 'Learning Type by Users';
                chart.addEventListener("click", handleLPByUsersChartClick);  
                break;

              case "64345637-e2a4-46c3-8bbc-1dc6d8dcc999":
                chartRefs.learningPathChart = chart;
                chartRefs.learningPathChart.chartName = 'Learning Paths for an Organization';
                chart.addEventListener("click", handleLPChartClick);  
                break;

              case "64832fbb-c5f2-4e27-8996-764f6c06ba05":
                chartRefs.userActivityChart = chart;
                chartRefs.userActivityChart.chartName = 'User Activity on a Learning Path';
                chart.addEventListener("click", handleUserActivityChartClick);
                break;

              case "650b14ac-73e9-44b2-88db-2d4da45b8eb8":
                chartRefs.topicsForLPChart = chart;
                chartRefs.topicsForLPChart.chartName = 'Topics for a Learning Path';
                chart.addEventListener("click", handleTopicsForLPChartClick);
                break;

              case "6509d769-8a91-46bc-8a1a-c27d72d21c69":
                chartRefs.userActivityTopicChart = chart;
                chartRefs.userActivityTopicChart.chartName = 'User Activity on a Topic';
                break;

              case "64908c9c-7539-4e7d-8df4-fc3420a5803d":
                chartRefs.userDetailsChart = chart;
                chartRefs.userDetailsChart.chartName = 'User Details';
                chart.addEventListener("click", handleUserDetailChartClick);
                break;
              
              case "654d471a-a453-44a3-8a2c-37a07d7ab933":
                subChartRefs.activeFormsChart = chart;
                subChartRefs.activeFormsChart.chartName = 'Forms';
                break;

              case "64bfbddd-94b5-4887-8a2e-0ce15c164827":
                subChartRefs.subscriptionSummaryChart = chart;
                subChartRefs.subscriptionSummaryChart.chartName = 'Subscription Summary';
                chart.addEventListener("click", handleSubscriptionSummaryChartClick);
                break;

              case "64c0fbdf-e8ed-4cc2-8448-92255829adab":
                subChartRefs.subscriptionUsageChart = chart;
                subChartRefs.subscriptionUsageChart.chartName = 'Subscription usage by Component';
                break;
          
              default:
                break;
            }
          });

          setCharts(chartRefs);
          setSubCharts(subChartRefs);
          setRendered(true);
        })      
      // })
    };

    renderDashboard();
  }, [selectedOrganizations]);

  useEffect(() => {
    selectedOrganizationsRef.current = selectedOrganizations;
  }, [selectedOrganizations]);

  useEffect(() => {
    selectedChartIdRef.current = selectedChartId;
  }, [selectedChartId]);

  useEffect(() => {
    selectedLearningPathRef.current = selectedLearningPath;
  }, [selectedLearningPath]);

  useEffect(() => {
    selectedUserRef.current = selectedUser;
  }, [selectedUser]);

  useEffect(() => {
    selectedTopicRef.current = selectedTopic;
  }, [selectedTopic]);

  useEffect(() => {
    selectedOrgTypeRef.current = selectedOrgType;
  }, [selectedOrgType]);

  useEffect(() => {
    selectedOrgLanguageRef.current = selectedOrgLanguage;
  }, [selectedOrgLanguage]);
  
  useEffect(() => {
    const applySubscriptionChartsFilter = () => {
      if (selectedOrganizations.length > 0) {
      
        if (rendered) {
          Object.values(subCharts).forEach((subChart) => {
            if (subChart) {
              subChart.setFilter({"organization.name": { $in: selectedOrganizations }});
            }
          });
        }
      }
    };

    applySubscriptionChartsFilter();

  }, [subCharts]);

  useEffect(() => {
    const applyOrgFilter = () => {
      if (selectedOrganizations.length > 0) {

        if (rendered) {
          Object.values(charts).forEach((chart) => {
            if (chart) {
              chart.setFilter({organization: { $in: selectedOrganizations }});

              if (chart === charts.accountManagerChart) {
                chart.setFilter({"name": { $in: selectedOrganizations }});
              }
            }
          });
        }
      }
    };

    applyOrgFilter();

  }, [charts]);

  useEffect(() => {
    const applySubFilters = () => {
      if (selectedOrganizations.length > 0) {
        
        if (rendered) {
          Object.values(subCharts).forEach((subChart) => {
            if (selectedComponentId !== null) {
              if (subChart === subCharts.subscriptionUsageChart) {
                subChart.setFilter({
                  "organization.name": { $in: selectedOrganizations },
                  "resources.refId": { $eq: selectedComponentId }
                });
              }
            } 
            if (selectedLearningPath !== null) {
              if (subChart === subCharts.activeFormsChart) {
                subChart.setFilter({
                  "organization.name": { $in: selectedOrganizations },
                  "learningPath.name": { $eq: selectedLearningPath }
                });
              }
            }   
            if (selectedOrgType !== null) {
              if (subChart === subCharts.activeFormsChart) {
                subChart.setFilter({
                  "organization.name": { $in: selectedOrganizations },
                  "learningPath.contentType": {$eq: selectedOrgType}
                });
              }
            }
          });
        }
      }
    };

    applySubFilters();

  }, [selectedComponentId,subCharts,selectedLearningPath,selectedOrgType]);
  
  useEffect(() => {
    const applyFilters = () => {
      if (selectedOrganizations.length > 0) {
        let lpFilter = {
          organization: { $in: selectedOrganizations },
          learningPath: { $eq: selectedLearningPath },
        };

        let orgLanguageFilter = {
          organization: { $in: selectedOrganizations },
          language: { $eq: selectedOrgLanguage }, 
        };

        let orgTypeFilter = {
          organization: { $in: selectedOrganizations },
          contentType: { $eq: selectedOrgType }, 
        };

        let lpTopicFilter = {
          organization: { $in: selectedOrganizations },
          sectionName: { $eq: selectedTopic }
        };

        let userFilter = {
          organization: { $in: selectedOrganizations },
          username: { $eq: selectedUser }
        }

        Object.values(charts).forEach((chart) => {
          if (chart) {
            if (selectedOrgLanguage !== null) {
              if (chart === charts.learningPathChart || chart === charts.totalUsersChart || chart === charts.lpTypeByUsersChart) {
                chart.setFilter(orgLanguageFilter);
              }
            }

            if (selectedOrgType !== null) {
              if (chart === charts.learningPathChart || chart === charts.totalUsersChart || chart === charts.orgLanguagesChart) {
                chart.setFilter(orgTypeFilter);
              }
            }

            if (selectedLearningPath !== null) {
              if (chart === charts.userActivityChart || chart === charts.topicsForLPChart || chart === charts.userActivityTopicChart || chart === charts.userDetailsChart || chart === charts.topicsForLPChart) {
                chart.setFilter(lpFilter);
              }
            }

            if (selectedUser !== null) {
              if (chart === charts.userDetailsChart) {
                chart.setFilter(userFilter);
              }
              if (chart === charts.userActivityTopicChart) {
                chart.setFilter(userFilter);
              }
            }

            if (selectedTopic !== null) {
              if (chart === charts.userActivityTopicChart) {
                chart.setFilter(lpTopicFilter);
                if (selectedUser !== null) {
                  lpTopicFilter.username = { $eq: selectedUser };
                  chart.setFilter(lpTopicFilter);
                }
              }
            }
          }
        });

      }
    };

    applyFilters();

  }, [selectedLearningPath,selectedUser,selectedTopic,selectedOrgLanguage,selectedOrgType,charts]);

  useEffect(() => {
    const applyFiltersUp = () => {
      if (selectedUserDetail !== null) {
        let userDetailFilter = {
          username: { $eq: selectedUserDetail },
        };

        Object.values(charts).forEach((chart) => {
          if (chart) {
            if (selectedUserDetail !== null ) {
              if (chart === charts.userActivityChart) {
                chart.setFilter(userDetailFilter);
              }
            }
          }
        });
      }
    };

    applyFiltersUp();
  }, [selectedUserDetail, charts]);

  useEffect(() => {
    const resetLearningPath = () => {
      setSelectedLearningPath(null);
    };

    if (selectedOrganizations.length > 0) {
      resetLearningPath();
    }
  }, [selectedOrganizations]);

  useEffect(() => {
    const resetUserActivity = () => {
      setSelectedUser(null);
    }

    if (selectedLearningPath !== null || selectedOrganizations.length > 0) {
      resetUserActivity();
    }
  }, [selectedLearningPath, selectedOrganizations]);

  useEffect(() => {
    const getOrganizationsName = async () => {
        let orgChart = sdk.createChart({
          chartId: "6499b8eb-0bb9-428b-8e53-f175e53c12c4",
        });

        await orgChart.render(chartDiv.current);
        orgChart.getData().then((data) => {
          const uniqueOrgNames = data.documents.reduce((accumulator, current) => {
            if (!accumulator.includes(current.group_series_0)) {
              accumulator.push(current.group_series_0);
            }
            return accumulator;
          },[]
        );

        setOrgNames(uniqueOrgNames.sort());
        
        });
    };

    getOrganizationsName();
  }, []);

  const handleClearButton = () => {

    nullFilters();

    Object.values(charts).forEach((chart) => {
      if (chart) {
        chart.setFilter({organization: { $in: selectedOrganizations }});

        if (chart === charts.accountManagerChart) {
          chart.setFilter({"name": { $in: selectedOrganizations }});
        }
      }
    });

    Object.values(subCharts).forEach((subChart) => {
      if (subChart) {
        subChart.setFilter({"organization.name": { $in: selectedOrganizations }});
      }
    });
  } 

  const nullFilters = () => {
    setSelectedLearningPath(null);
    setSelectedUser(null);
    setSelectedTopic(null);
    setSelectedUserDetail(null);
    setSelectedComponentId(null);
    setSelectedOrgLanguage(null);
    setSelectedOrgType(null);
  } 
    
  const reloadDashboard = () => {
    if (rendered) {
      dashboard.refresh();
    }
  }

  const isDateWithoutTime = (inputString) => {
    const dateFormatPattern = /^(0[1-9]|[12][0-9]|3[01])-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/;
    return dateFormatPattern.test(inputString);
  }

  const isValidDateFormat = (inputString) => {
    if (typeof inputString === 'string' && isDateWithoutTime(inputString)) {
      if (!isNaN(Date.parse(inputString))) {
        return true;
      }
    }

    if (inputString instanceof Date) {
      return true;
    }

    return false;
  }

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (isDateWithoutTime(inputDate)) {
      return formattedDate = `${month}/${day}/${year}`;
    }

    return formattedDate = `${month}/${day}/${year} ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  }
  
  const handleDownloadClick = async () => {
    setIsDownloading(true);
    const charts = await dashboard.getAllCharts();

    try {
      if (selectedChartId !== null) {
        Object.values(charts).forEach((chart) => {
          if (chart.chartId === selectedChartId) {
            chart.getData().then((data) => {
              chartName = chart.chartName.replace(/\s+/g, "");
              const headerRowValues = Object.values(data.fields);
              rows.push(headerRowValues);

              const headerRowKeys = Object.keys(data.fields);

              data.documents.forEach((document) => {
                const rowData = headerRowKeys.map((headerRowKey) => {
                  const cellValue = document[headerRowKey];

                  if (cellValue === null || cellValue === undefined || cellValue === "") {
                    return "";
                  } else if (cellValue === 0) {
                    return "0";
                  } else if (isValidDateFormat(cellValue)) {
                    return formatDate(cellValue);
                  } else {
                    return cellValue.toString();
                  }
                });
                rows.push(rowData);
              });

              const csv = rows
              .map((row) =>
                row
                  .map((cell) => {
                    if (cell === null || cell === undefined || cell === "") {
                      return "N/A"; 
                    } else if (cell.toString().includes(",")) {
                      return `"${cell}"`;
                    } else {
                      return cell.toString();
                    }
                  })
                  .join(',')
              )
              .join('\n');
  
              const blob = new Blob([csv], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = `${chartName}Chart.csv`;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              setIsDownloading(false);
            });
          }
        });
      }
    } catch (error) {
      console.error('Error during download:', error);
      setIsDownloading(false);
    }
  };

  const handleLPChartClick = async (payload) => {
    const clickedData = payload.data;
    let clickedLearningPath = clickedData.groups.find((g) => g.label === "Learning Path").value;
    setSelectedLearningPath(clickedLearningPath);
  };

  const handleUserActivityChartClick = async (payload) => {
    const clickedData = payload.data;
    let clickedUser = clickedData.groups.find((g) => g.label === "User Name ").value;
    setSelectedUser(clickedUser);
  };

  const handleTopicsForLPChartClick = async (payload) => {
    const clickedData = payload.data;
    let clickedTopic = clickedData.groups.find((g) => g.label === "Topic Name").value;
    setSelectedTopic(clickedTopic);
  };

  const handleUserDetailChartClick = async (payload) => {
    const clickedData = payload.data;
    let clickedUser = clickedData.groups.find(
      (g) => g.label === "Username"
    ).value;
    setSelectedUserDetail(clickedUser);
  };

  const handleSubscriptionSummaryChartClick = async (payload) => {
    const clickedData = payload.data;
    let clickedComponent = clickedData.groups.find(
      (g) => g.label === "Component ID"
    ).value;
    setSelectedComponentId(clickedComponent);
  }

  const handleOrgLanguagesChartClick = async (payload) => {
    let clickedData = payload.data;

    if (clickedData) {
        if (clickedData.label && clickedData.label.value) {
          setSelectedOrgLanguage(clickedData.label.value);
        } else if (clickedData.color && clickedData.color.value) {
          setSelectedOrgLanguage(clickedData.color.value);
        }
    }
  };

  const handleLPByUsersChartClick = async (payload) => {
    let clickedData = payload.data;

    if (clickedData) {
        if (clickedData.label && clickedData.label.value) {
          setSelectedOrgType(clickedData.label.value);
        } else if (clickedData.color && clickedData.color.value) {
          setSelectedOrgType(clickedData.color.value);
        }
    }
  };

  const onClickSelect = (event, value) => {
    if (value.length === 0) {
      window.location.reload();
    } else {
      setSelectedOrganizations(value);
    }
  };
  
  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <div className="dashboard-header_logo">
          <img className="logo" src="images/logo.png" />
        </div>
        <div className="dashboard-header_content">
          <div className="dashboard-header_content-items">
            <div className="dashboard-header_content-items_title">
              <h1 className="title">Health Check Dashboard</h1>
            </div>

            <div className="dashboard-header_content-items_buttons">
              <div className="dashboard-header_content-items_buttons-reload">
                <button
                  className="reloadButton"
                  type="submit"
                  onClick={reloadDashboard}
                >
                  <FontAwesomeIcon icon={faRotate} size="xl" />
                </button>
              </div>
              <div className="dashboard-header_content-items_buttons-logout">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "#1e5982", height: 41 }}
                  onClick={logOutUser}
                >
                  Log Out
                </Button>
              </div>
              <div className="dashboard-header_content-items_buttons-download">
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="modal-popup">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Select one of the charts
                    </Typography>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="chart-data">Chart</InputLabel>
                        <Select
                          labelId="chart-data"
                          id="chart-select"
                          label="Chart"
                          value={selectedChartId || ""}
                          onChange={(event, value) =>
                            setSelectedChartId(event.target.value)
                          }
                          disabled={Object.keys(charts).length === 0}
                        >
                          {Object.values(charts)
                            .filter(
                              (chart) =>
                                ![
                                  "6544ddb3-903f-4e66-81ca-5ccc49f4c736",
                                  "0042022c-6ad8-43eb-a49a-a27ad1fce993",
                                  "chard98cfc44-dafc-448b-ae95-d6d88851d0c4tId3",
                                  "d98cfc44-dafc-448b-ae95-d6d88851d0c4",
                                ].includes(chart.chartId)
                            )
                            .map((chart) => (
                              <MenuItem
                                key={chart.chartId}
                                value={chart.chartId}
                                name={chart.chartName}
                              >
                                {chart.chartName}
                              </MenuItem>
                            ))}

                          {Object.values(subCharts).map((subchart) => (
                            <MenuItem
                              key={subchart.chartId}
                              value={subchart.chartId}
                              name={subchart.chartName}
                            >
                              {subchart.chartName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Button
                      className="download-btn"
                      type="submit"
                      variant="contained"
                      sx={{ bgcolor: "#1e5982", height: 41, mr: 1.2 }}
                      onClick={handleDownloadClick}
                      disabled={isDownloading || selectedChartId === null}
                    >
                      Download CSV
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ bgcolor: "#1e5982", height: 41 }}
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    {isDownloading ? (
                      <div
                        className="reload-icon"
                        onClick={handleDownloadClick}
                      >
                        <CircularProgress />
                        <span className="message">
                          Please wait, your file is being generated!
                        </span>
                      </div>
                    ) : null}
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
          <div className="dashboard-header_content-dropdown">
            <Autocomplete
              multiple
              limitTags={2}
              id="tags-outlined"
              options={orgNames}
              onChange={(event, value) => onClickSelect(event, value)}
              // onClose={handleCloseOnAutocomplete}
              // clearIcon={null}
              value={selectedOrganizations}
              getOptionDisabled={(options) => (selectedOrganizations.length > 2 ? true : false)}
              filterSelectedOptions
              sx={{
                width: 577,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organization Name"
                />
              )}
              disabled={orgNames.length === 0}
            />
          </div>

          <div className="dashboard-header_content-buttons">
            <div className="dashboard-header_content-buttons_clear">
              <Button
                type="submit"
                variant="contained"
                sx={{ bgcolor: "#1e5982", height: 41, mt: 2, width: 145 }}
                onClick={handleClearButton}
              >
                Clear Filters
              </Button>
            </div>
            <div className="dashboard-header_content-buttons_download">
              <Button
                type="submit"
                variant="contained"
                sx={{ bgcolor: "#1e5982", height: 41, mt: 2, width: 145 }}
                onClick={handleOpen}
              >
                Export CSV
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-body">
        <div style={{ display: "none" }} ref={chartDiv}></div>
        { selectedOrganizations && selectedOrganizations.length > 0 && <div className="dashboard" ref={dashboardDiv}></div>}
      </div>
    </div>
  );
};

export default Highlight;
