import { Routes, Route, BrowserRouter } from 'react-router-dom';
import React from 'react';
import "./App.css";
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ProtectedRoute from './pages/ProtectedRoute';

const App = () => {
  return (
    <>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard dashboardId={'7c70dc38-b32e-41be-a94d-5a431864216f'}/>
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
        </Routes>
    </>
  );
};

export default App;