// Modify UserContext.js
import { createContext, useState, useEffect } from "react";
import { App, Credentials } from "realm-web";
import { getRealmUserToken } from "@mongodb-js/charts-embed-dom";

const APP_ID = process.env.REACT_APP_APP_ID;

// Creating a Realm App Instance
const app = new App(APP_ID);

// Creating a user context to manage and access all the user related functions
// across different component and pages.
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUserToken = async () => {
    return await getRealmUserToken(app);
  };

  // Function to login user into our Realm using their email & password
  const emailPasswordLogin = async (email, password) => {
    const credentials = Credentials.emailPassword(email, password);
    const authedUser = await app.logIn(credentials);
    setUser(authedUser);
    return authedUser;
  };

  // Function to fetch-user(if the user is already logged in) from local storage
  const fetchUser = async () => {
    setLoading(true);
    if (!app.currentUser) {
      setLoading(false);
      return false;
    }
    try {
      await app.currentUser.refreshCustomData();
      // Now if we have a user we are setting it to our user context
      // so that we can use it in our app across different components.
      setUser(app.currentUser);
      return app.currentUser;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Function to logout user from our Realm
  const logOutUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.logOut();
      // Setting the user to null once loggedOut.
      setUser(null);
      return true;
    } catch (error) {
      throw error;
    }
  };

  const handleLogin = async (email, password) => {
    const credentials = Credentials.emailPassword(email, password);
    return await app.logIn(credentials);
  };

  // Fetch the user when the component mounts
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <UserContext.Provider
      value={{ user, setUser, fetchUser, emailPasswordLogin, logOutUser, getUserToken, handleLogin, loading }}
    >
      {children}
    </UserContext.Provider>
  );
};
