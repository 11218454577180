import { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserContext } from "../contexts/user.context";
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";


const defaultTheme = createTheme();

export default function SignIn() {

  const navigate = useNavigate();
  
  const { user, fetchUser, emailPasswordLogin, handleLogin } = useContext(UserContext);

  const loadUser = async () => {
    if (!user) {
      const fetchedUser = await fetchUser();
      if (fetchedUser) {
        navigate("/dashboard");
      }
    }
  }

  useEffect(() => {
    loadUser();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const user = await emailPasswordLogin(data.get('email'), data.get('password'));
      if (user) {
        await handleLogin(data.get('email'), data.get('password'));
        navigate("/dashboard");
      }
    } catch (error) {
      alert("It seems your username or password is incorrect. Please verify and try again.")
      navigate("/login");
    }
  };

  return (
    <div className='container'>
      <div className='header'>
        <img className="hero" src="images/charts.png" />
      </div>
      <div className='body'>
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: '#1e5982' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Box textAlign='center'>
                  <Button type="submit" variant='contained' sx={{bgcolor: '#1e5982', width: 130}}>
                    Sign In
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider></div>
      <div className='footer'>
        <img className="footer-logo" src="images/logo.png" />
      </div>
    </div>
  );
}